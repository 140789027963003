import React, {useState} from "react";
import {CompactCoupon} from "./CompactCoupon";
import {RawLargeCoupon} from "./LargeCoupon";
import {MediumCoupon} from "./MediumCoupon";
import {Select} from "../sharedComponents/Select";

function LargeCoupon({isSelected,onClick, color, secondaryColor,bandState,actualBand}) {
    const availableBands = actualBand.getNextBands(2).reverse();
    const availableBandsNames = availableBands.map(band => band.getName());
    const [selectedBandIndex, setSelectedBandIndex] = useState(() => availableBands.indexOf(actualBand.getNextBand()));

    return <RawLargeCoupon
        isSelected={isSelected}
        onClick={(event) => {
            if (isSelected) return;
            bandState.handleBandChange(availableBands[selectedBandIndex]);
            onClick(event);
        }}
        color={color}
        title={"Se Propone"}
        secondaryColor={secondaryColor}
        description={<>Es la estrategia de aumento esperada para representar el crecimiento promedio de une pine en 10Pines.</>}
    >
        <div style={{"marginRight": "1rem"}}>
            <b> Se propone a : </b>
            <Select
                disabled={!isSelected}
                style={{ width: "8rem"}}
                value={availableBandsNames[selectedBandIndex]}
                onChange={(event) => {
                    const newSelectedBandIndex = availableBandsNames.indexOf(event.target.value);
                    bandState.handleBandChange(availableBands[newSelectedBandIndex]);
                    setSelectedBandIndex(newSelectedBandIndex);
                }}
                options={availableBands}
            />
        </div>
    </RawLargeCoupon>
}

export function Proposed({size,isSelected,onClick,bandState,actualBand,percentage}) {
    if(size === 'compact'){
        return <CompactCoupon secondaryColor="cornflowerblue" color="yellowgreen" defaultText={"SP"} textOnHover={`${percentage}%`}/>
    }
    if(size === 'large'){
        return <LargeCoupon secondaryColor="cornflowerblue" color="yellowgreen" isSelected={isSelected} onClick={onClick} bandState={bandState} actualBand={actualBand}/>
    }
    return <MediumCoupon secondaryColor={"cornflowerblue"} color="yellowgreen"
                         defaultText={`Se Propone`} />
}
