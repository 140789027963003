import React, {useState} from "react";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {ProposalSection} from "./ProposalSection";
import {PineLastRisesSection} from "./PineLastRisesSection";
import {Tab as RawTab} from "@material-ui/core";
import {isMediumScreenOrLess} from "../commons";

export function ProposalTabs(props) {
    const handleChangeTab = (event, newValue) => {
        setTabValue( newValue);
    };

    const [tabValue,setTabValue] = useState("0")
    return <TabContext value={tabValue}>
        <TabList indicatorColor={"primary"} onChange={handleChangeTab} centered={ isMediumScreenOrLess() }>
            <Tab label="Agregar Propuesta" value={"0"} ></Tab>
            <Tab label="Ultimos Aumentos" value={"1"} ></Tab>
        </TabList>

        <TabPanel value={"0"}>
            <ProposalSection pine={props.pine}
                             allPines={props.allPines}
                             revisionData={props.revisionData}
                             onAddProposal={props.onAddProposal}
                             onCancel={props.handleProposalCancellation}
                             readOnly={props.shouldItemsByReadOnly}
            />
        </TabPanel>
        <TabPanel value={"1"}>
            <PineLastRisesSection pine={props.pine}
                                  onCancel={props.handleLastRisesExit}/>
        </TabPanel>
    </TabContext>;

}

const Tab = (props) => {
    return <RawTab {...props}/>
}
