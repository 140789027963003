import React from 'react'
import {Benefit} from "../models/Benefit";
import {getBackendApi} from "../api/BackendApiProvider";

export class BenefitsRepository {
    static cachedBenefits = [];

    static initialize = () => {
        return getBackendApi()
            .getBenefits()
            .then(rawBenefits => {
                this.cachedBenefits = rawBenefits.map(rawBenefit => new Benefit(rawBenefit));
                return this.cachedBenefits;
            });
    }

    static getCurrentBenefits(){
        return this.cachedBenefits;
    };

}
