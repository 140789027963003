import {toast} from 'react-toastify';
import {BackendApi} from "./BackendApi";

export class MockedBackendApi {
  static mockedDataAsPromise = (mockedData) => new Promise((resolve => setTimeout(() => resolve(mockedData),0)));
  static emptyPromiseResponse = () => this.mockedDataAsPromise();
  static failingPromiseResponse = () => Promise.reject({message: "Error"});
  static errorCallback = (error, message) => {
    console.error(error);
    toast.error(message, {position: toast.POSITION.BOTTOM_RIGHT});
    throw error;
  };

  //////////////////////////////////////////////////// PINES API ///////////////////////////////////////////////////////

  static getPines(revisionId) {
    return BackendApi.getPines(revisionId);
  }

  static addGardener(pineId, gardenerId) {
    return BackendApi.addGardener(pineId, gardenerId);
  }

  static updateGardeners(pineId, gardenerIds) {
    return BackendApi.updateGardeners(pineId, gardenerIds);
  }

  static deletePine(pineId, optionalDate) {
    return BackendApi.deletePine(pineId, optionalDate);
  }

  static removeGardener(pineId, gardenerId) {
    return BackendApi.removeGardener(pineId, gardenerId);
  }

  static disablePine() {
    return this.emptyPromiseResponse().catch(error => this.errorCallback(error, "Ocurrió un error al remover el pino"));
  }

  static getCurrentPine() {
    return BackendApi.getCurrentPine();
  }

  static logout() {
    return BackendApi.logout();
  }

  //////////////////////////////////////////////////// BENEFITS API ///////////////////////////////////////////////////////

  static getBenefits() {
    return BackendApi.getBenefits();
  }

  //////////////////////////////////////////////////// BANDS API ///////////////////////////////////////////////////

  static getBands() {
    return BackendApi.getBands();
  }

  //////////////////////////////////////////////////// PROPOSALS API ///////////////////////////////////////////////////
  static createProposal(rawProposalData) {
    return BackendApi.createProposal(rawProposalData);
  }

  static addWeakSponsor(proposalId, sponsorId) {
    return BackendApi.addWeakSponsor(proposalId, sponsorId);
  }

  static removeWeakSponsor(proposalId, sponsorId) {
    return BackendApi.removeWeakSponsor(proposalId, sponsorId);
  }

  static getPineLastRises(pineId) {
    return BackendApi.getPineLastRises(pineId);
  }

  //////////////////////////////////////////////////// REVISION API ////////////////////////////////////////////////////

  static getRevisions() {
    return BackendApi.getRevisions();
  }

  static getRevision(revisionId,withPines){
    return BackendApi.getRevision(revisionId,withPines);
  }

  static getCurrentRevision() {
    return BackendApi.getCurrentRevision();
  }

  static updateCurrentProposedPineId(pineId) {
    return BackendApi.updateCurrentProposedPineId(pineId);
  }

  static createAdjustmentRevision(adjustment, impactMonth,excludedPines){
    return BackendApi.createAdjustmentRevision(adjustment, impactMonth,excludedPines);
  }

  static closeRevision(impactDate) {
    return BackendApi.closeRevision(impactDate);
  }

  static getLoggedPine() {
    return BackendApi.getLoggedPine()
  }

  static createExpressRevision(){
    return BackendApi.createExpressRevision();
  }
  static saveExpressRevision(revisionId, impactMonth){
    return BackendApi.saveExpressRevision(revisionId, impactMonth);
  }

  static deleteRevision(revisionId){
    return BackendApi.deleteRevision(revisionId);
  }

}
