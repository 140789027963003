import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const iconContainerStyle = (borderColor) => {
    return {
        border: `0.1rem solid ${borderColor}`,
        borderRadius: "200%",
        height: "1rem",
        width: "1rem",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "0.5rem",
        paddingTop: "0.4rem",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        marginRight: "0.5rem"
    }
};

export const Icon = props => (
    <div style={iconContainerStyle(props.borderColor)}>
        <FontAwesomeIcon icon={props.icon} color={props.borderColor}/>
    </div>
);
