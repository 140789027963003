import {Recategorization} from "../Coupon/Recategorization";
import {Proposed} from "../Coupon/Proposed";
import {NotProposed} from "../Coupon/NotProposed";

export const riseStrategies = {
    RELOCATE: {
        id: "relocate",
        getPercentage: (pine, selectedBand) => selectedBand.calculateRisePercentageToMe(pine.getOldRate()),
        getProposedBand: (_pine, selectedBand) => selectedBand,
        coupon: Recategorization
    },
    STANDARD: {
        id: "standard",
        getPercentage: (pine, selectedBand) => selectedBand.calculateRisePercentageToMe(pine.getOldRate()),
        getProposedBand: (_pine, selectedBand) => selectedBand,
        coupon: Proposed
    },
    NO_RAISE: {
        id: "no raise",
        getPercentage: (_pine, _selectedBand) => 0,
        getProposedBand: (pine, _selectedBand) => pine.getBand(),
        coupon: NotProposed
    }
};

export const strategyFromPine = pine => {
    return wasCreatedBeforeHavingSubBands(pine.getProposal()) ?
        strategyForPineBasedOnPercentage(pine) :
        strategyForPineBasedOnBand(pine);
};

/*
 * Esto es necesario debido a que antes de Julio del 2023 uno se podía presentar normalmente (7%) y seguir en la misma franja,
 * por ejemplo, pasando de Padawan 1 (base) a Padawan 1 (mitad).
 *
 * A partir de esa fecha, cada vez que alguien se presenta a un aumento normal significa que cambia de franja (porque
 * Padawan 1 Base y Padawan 1 Mitad ahora son franjas), y no necesariamente el aumento fue del 7%. Con lo cual
 * pierde el sentido la implementación anterior que estaba basada en porcentajes.
 *
 * La primera revisión en la que se aplicó esta lógica fue en la de Agosto 2023.
 */
function wasCreatedBeforeHavingSubBands(proposal) {
    const julyFirst2023 = new Date(2023, 6, 1);
    return proposal.getDate() < julyFirst2023;
}

export const strategyForPineBasedOnPercentage = (pine) => {
    const percentage = pine.getProposalPercentage() || 0;

    if (percentage === 0) {
        return riseStrategies.NO_RAISE;
    } else if (percentage === 7) {
        return riseStrategies.STANDARD;
    } else {
        return riseStrategies.RELOCATE;
    }
};

export const strategyForPineBasedOnBand = (pine) => {
    const proposedBand = pine.getProposalBand();
    const bandBeforeProposal = pine.getBand();

    if (proposedBand === bandBeforeProposal) {
        return riseStrategies.NO_RAISE;
    } else if (proposedBand.isTheNextOf(bandBeforeProposal)) {
        return riseStrategies.STANDARD;
    } else {
        return riseStrategies.RELOCATE;
    }
};

const getRisesStrategiesAsArray = () => {
    return [riseStrategies.RELOCATE, riseStrategies.STANDARD, riseStrategies.NO_RAISE];
};

export const getRiseStrategyById = (id) => {
    return getRisesStrategiesAsArray().find(strat => id === strat.id);
};
