import React from 'react'
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withAuthContext} from "../AuthContainer";

export class WeakSponsorThumbsUp extends React.Component {

  handleClick = (event) => {
    if(!this.props.readOnly){
      this.props.onWeakSponsorToggle(this.props.pine, this.props.loggedPine);
    }
    event.stopPropagation();
  };

  render() {
    const sponsoredCssClass = this.props.sponsored ? "sponsored" : "";
    return (
      <div className={`weak-sponsor-thumbs-up ${this.props.readOnly ? "" : "pointer"}`} onClick={this.handleClick}>
        <FontAwesomeIcon className={`icon ${sponsoredCssClass}`}
                         icon={faThumbsUp}
                         size="2x"
        />
        <p className={`amount-of-weak-sponsors ${sponsoredCssClass}`}> {this.props.pine.amountOfWeakSponsors()} </p>
      </div>
    );
  }
}
const WeakSponsorThumbsUpEditable = (props) =>  {
  const {pine,loggedPine} = props
  const isWeakSponsor = () => {
    return loggedPine && pine.isPineWeakSponsor(loggedPine);
  };

  return <WeakSponsorThumbsUp {...props} sponsored={isWeakSponsor()}/>
};

export const WeakSponsorThumbsUpReadOnly = (props) =>  {
  return <WeakSponsorThumbsUp {...props} sponsored={false} readOnly={true}/>
};

export const WeakSponsorThumbsUpEditableContainer = withAuthContext(WeakSponsorThumbsUpEditable);
