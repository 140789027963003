import axios from 'axios';
import {toast} from "react-toastify";

export class BackendApi {
  static backendBasicPath = '/api';
  static errorCallback = (error, message) => {
    console.error(error);
    toast.error(message, {position: toast.POSITION.BOTTOM_RIGHT});
    throw error;
  };

  //////////////////////////////////////////////////// PINES API ///////////////////////////////////////////////////////

  static getPines(revisionId) {
    return axios
      .get(`${this.backendBasicPath}/pinos?idRevision=` + revisionId)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al conseguir los pinos"));
  }

  static deletePine(pineId, optionalDate) {
    return axios.delete(this.backendBasicPath + "/pinos/" + pineId, { data: {fecha: optionalDate}})
        .catch(error => this.errorCallback(error, "Ocurrió un error al eliminar a la persona"));
  }

  static createPine(newPine){
    return axios.post(this.backendBasicPath + "/pinos/",  {nombre: newPine.name, legajo: newPine.file, fecha_ingreso:newPine.startDate, id_backoffice: newPine.backofficeId, rate: newPine.rate})
      .catch(error => this.errorCallback(error, "Ocurrió un error al crear al pine"));
  }

  static addGardener(pineId, gardenerId) {
    return axios.post(this.backendBasicPath + "/pinos/" + pineId + "/jardineros", {idJardinero: gardenerId})
      .catch(error => this.errorCallback(error, "Ocurrió un error al agregarte como jardinere"));
  }

  static updateGardeners(pineId, gardenerIds) {
    return axios.put(this.backendBasicPath + "/pinos/" + pineId + "/jardineros", {idJardineres: gardenerIds})
        .catch(error => this.errorCallback(error, "Ocurrió un error al actualizar les jardineres"));
  }

  static removeGardener(pineId, gardenerId) {
    return axios.delete(this.backendBasicPath + "/pinos/" + pineId + "/jardineros", {data: {idJardinero: gardenerId}})
      .catch(error => this.errorCallback(error, "Ocurrió un error al removerte como jardinere"));
  }

  static disablePine(pineId) {
    return axios.delete(this.backendBasicPath + "/pinos/" + pineId)
      .catch(error => this.errorCallback(error, "Ocurrió un error al remover el pino"));
  }

  static getCurrentPine() {
    return axios
      .get(`${this.backendBasicPath}/revisiones/actual/pinoEnRevision`)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al obtener el pino actual"));
  }

  static getLoggedPine() {
    return axios
        .get(`${this.backendBasicPath}/pinos/logged_pine`)
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al obtener el pino loggeado"));
  }

  static logout() {
    return axios
        .post('/auth/logout')
        .catch(error => this.errorCallback(error, "Ocurrió un error al desloguearse"));
  }

  //////////////////////////////////////////////////// BANDS API ///////////////////////////////////////////////////////

  static getBenefits() {
    return axios
        .get(`${this.backendBasicPath}/beneficios`)
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al obtener los beneficios"));
  }
  //////////////////////////////////////////////////// BANDS API ///////////////////////////////////////////////////////

  static getBands() {
    return axios
      .get(`${this.backendBasicPath}/franjas`)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al obtener las bandas"));
  }

  //////////////////////////////////////////////////// PROPOSALS API ///////////////////////////////////////////////////

  static createProposal(proposalData) {
    return axios
      .post(this.backendBasicPath + "/propuestas", {...proposalData, estado: proposalData.status.backendName})
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al actualizar tu propuesta"));
  }

  static addWeakSponsor(proposalId, sponsorId) {
    return axios.post(this.backendBasicPath + "/propuestas/" + proposalId + "/sponsorDebil", {idSponsor: sponsorId})
      .catch(error => this.errorCallback(error, "Ocurrió un error al agregar tu sponsoreo"));
  }

  static removeWeakSponsor(proposalId, sponsorId) {
    return axios.delete(this.backendBasicPath + "/propuestas/" + proposalId + "/sponsorDebil", {data: {idSponsor: sponsorId}})
      .catch(error => this.errorCallback(error, "Ocurrió un error al removerte como sponsor"));
  }

  static getPineLastRises(pineId) {
    return axios
      .get(this.backendBasicPath + "/propuestas?pinoId=" + pineId)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al solicitar los aumentos"));
  }

  //////////////////////////////////////////////////// REVISION API ////////////////////////////////////////////////////

  static getRevisions() {
    return axios
      .get(`${this.backendBasicPath}/revisiones`)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al solicitar las revisiones"));
  }

  static getCurrentRevision(withPines) {
    return this.getRevision('actual', withPines)
  }

  static getRevision(revisionId, withPines){
    return axios
      .get(`${this.backendBasicPath}/revisiones/${revisionId}/?debe_serializar_pines=${withPines}`)
      .then(res => res.data)
      .catch(error => this.errorCallback(error, `Ocurrió un error al solicitar la revisión ${revisionId}`));
  }

  static closeRevision(impactDate) {
    return axios
        .patch(this.backendBasicPath + "/revisiones", {impactDate: impactDate})
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al cerrar la revisión"));
  }

  static updateCurrentProposedPineId(pineId) {
    return axios
      .put(this.backendBasicPath + '/revisiones/actual/pinoActualEnRevision', {idPinoActualEnRevision: pineId})
      .then(res => res.data)
      .catch(error => this.errorCallback(error, "Ocurrió un error al actualizar la revisión"));
  }

  static createAdjustmentRevision(adjustment, impactDate,excludedPines){
    return axios
        .post(this.backendBasicPath + '/revisiones/ajuste',{ajuste: adjustment,fechaDeImpacto: impactDate,pinosExcluidos: excludedPines})
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al crear la revisión"));

  }

  static createExpressRevision(){
    return axios
        .post(this.backendBasicPath + '/revisiones/express')
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al crear la revisión"));
  }

  static saveExpressRevision(revisionId, impactDate){
    return axios
        .patch(`${this.backendBasicPath}/revisiones/express/` + revisionId, {revisionId: revisionId, fechaDeImpacto: impactDate})
        .then(res => res.data)
        .catch(error => this.errorCallback(error, "Ocurrió un error al guardar la revisión"));

  }

  static deleteRevision(revisionId) {
    return axios.delete(this.backendBasicPath + "/revisiones/" + revisionId, {data: {id: revisionId}})
        .catch(error => this.errorCallback(error, "Ocurrió un error al eliminar la revision"));
  }


}
