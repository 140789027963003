import React from 'react'
import {withRouter} from "react-router-dom";
import Loading from "../sharedComponents/Loading";
import {Select} from "../sharedComponents/Select";
import {faQuestionCircle, faTree} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import PopUpButton from "../sharedComponents/PopUpButton";
import {CallToActionButton} from "../sharedComponents/interactions/CallToActionButton";
import _ from 'lodash'
import {create} from "../businessLogic/models/Pine";
import {InputDateNewPineModal, InputNewPineModal} from "./InputNewPineModal";

const pageContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%"
}

const headerStyle = {
  background: "white",
  borderRadius: "30px 30px 0 0 ",
  marginBottom: "2em",
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '1em 0em',
};

const bodyContainerStyle = {
  overflowY: "scroll",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "42em",
}

const tableContainerStyle = {
  width: "62%",
}

const nameStyle = {
  fontSize: "1.2em",
  fontWeight: "700",
  width: "40%"
}

const columns = [
  { id: 'pineName', label: 'Pine', minWidth: 100, jsx: (pine) => {
    return(
        <span style={nameStyle}> {pine.getName()}</span>
    )
    } },
  { id: 'gardeners', label: 'Jardineres', minWidth: 100, jsx: (pine, context) => {
    return(
        <div title={"Jardineres"} style={{display: "flex", alignItems: "center"}}>
          <Select
              value={pine.getGardenersNames()}
              style={{borderRadius: "0px", width: "20em", margin: "0.3em 1em", background: "transparent"}}
              multiple
              onChange={(event) => context.handleGardenersChange(event, pine)}
              options={context.props.pines}
          />
          <CallToActionButton isDisabled={false}
                              label={"Impactar Cambios"}
                              onClick={() => context.handleGardenersUpdateClick(pine)}/>
        </div>
    )
    } },
  { id: 'actions', label: 'Acciones', minWidth: 100, jsx: (pine, context) => {
      return(
        <PopUpButton
            textToShowInButton={"Eliminar"}
            disabled={() => context.state.selectedDeletePineDate === ""}
            textToShow={context.getDeletePineModalJSX()}
            handleOk={() => context.handleDeletePine(pine)}
            okChildren={() => "Eliminar"}
        />
    )
    } },
];

const deletePineModalBodyStyle = {
  display: "flex",
  alignItems: "center",
  margin: "1em 0em",
  background: "transparent",
  padding: "1em",
  borderRadius: "10px"
}
const compareRates = (a,b) => {return b.getNewRateOfPine() - a.getNewRateOfPine()};

class PinesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isLoading: false, pines: props.pines, selectedDeletePineDate: ""};
  }

  handleDeletePine (deletedPine) {
    deletedPine.delete(this.state.selectedDeletePineDate).then(() => {
      this.setState({selectedDeletePineDate: "", pines: this.props.pines.filter(pine => pine.id !== deletedPine.id)})
      toast.success(deletedPine.getName() + " ha sido eliminade con éxito", {position: toast.POSITION.BOTTOM_RIGHT});
    })
  }
  handleCreatePine () {
    const createdPine = {name: this.state.pineCreatedName, file:this.state.pineCreatedFile, startDate: this.state.pineCreatedStartDate,
      rate: this.state.pineCreatedRate, backofficeId: this.state.pineCreatedBackOfficeId}
    create(createdPine).then(( pine) => {
      this.setState({pines: this.props.pines.concat(pine).sort(compareRates)})
      toast.success(createdPine.name + " ha sido creade con éxito", {position: toast.POSITION.BOTTOM_RIGHT});
    })
  }

  handleDeletePineDateChange = (event) => {
    this.setState({selectedDeletePineDate: event.target.value});
  }

  handlePineCreatedFile = (event) => {
    this.setState({pineCreatedFile : event.target.value});
  }

  handlePineCreatedName = (event) => {
    this.setState({pineCreatedName : event.target.value});
  }

  handlePineCreatedBackOfficeId = (event) => {
    this.setState({pineCreatedBackOfficeId : event.target.value});
  }

  handlePineCreatedStartDate = (event) => {
    this.setState({pineCreatedStartDate : event.target.value});
  }

  handlePineCreatedRate = (event) => {
    this.setState({pineCreatedRate : event.target.value});
  }

  getDeletePineModalJSX = () => {
    return <div style={{display: "flex", flexDirection: "column"}}>
      <div style={deletePineModalBodyStyle}>
        <div
            title={"La persona eliminada continuará apareciendo hasta la última revisión que le incluya en la nómina"}
            style={{marginRight: "1rem", cursor: "pointer"}}
        >
          <FontAwesomeIcon color={"grey"} icon={faQuestionCircle} size={"2x"}/>
        </div>
        <span style={{fontSize: "1.1rem", marginRight: "1rem", fontWeight: "200"}}> Fecha de Salida: </span>
        <TextField
            id="date"
            type="date"
            style={{background: "transparent", margin: "0.3em 1em", padding: "0.5em", borderRadius: "10px"}}
            value={this.state.selectedDeletePineDate}
            onChange={this.handleDeletePineDateChange}
            InputLabelProps={{
              shrink: true,
            }}
        />
      </div>
    </div>;
  };

  getAddPineModalJSX = () => {
    return <div style={{width:"22rem"}}>
      <InputNewPineModal name="Legajo" value={this.state.pineCreatedFile} onChange={this.handlePineCreatedFile}/>

      <InputNewPineModal name="Nombre" value={this.state.pineCreatedName} onChange={this.handlePineCreatedName}/>

      <InputNewPineModal name="Backoffice ID" value={this.state.pineCreatedBackOfficeId} onChange={this.handlePineCreatedBackOfficeId}/>

      <InputDateNewPineModal value={this.state.pineCreatedStartDate} onChange={this.handlePineCreatedStartDate} />

      <InputNewPineModal name="Rate" value={this.state.pineCreatedRate} onChange={this.handlePineCreatedRate}/>
    </div>
  };

  handleGardenersChange = (event, pine) => {
    pine.gardeners = event.target.value.map(gardenerName => {
      const completedGardener = this.props.pines.find(p => p.getName() === gardenerName);
      return {"id": completedGardener.getId(), "nombre": gardenerName};
    });
    this.replacePine(pine);
  };

  handleGardenersUpdateClick = (pine) => {
    return pine.updateGardenersByName(this.props.pines, pine.gardeners.map(gardener => gardener.nombre)).then(() => {
      this.replacePine(pine);
      toast.success("Se actualizaron les jardineres de " + pine.getName(), {position: toast.POSITION.BOTTOM_RIGHT})
    });
  }

  replacePine = (pine) => {
    const pines = _.clone(this.state.pines);

    let index = _.findIndex(pines,aPine => aPine.getId() === pine.getId());
    pines[index]= pine;
    this.setState({pines});
  }

  render() {
    if (this.state.isLoading)
      return <Loading/>

    return (
      <div style={pageContainerStyle}>
        <div style={headerStyle}>
          <h1 style={{fontWeight: "200"}}> Pines y Jardineres </h1>
          <PopUpButton
              textToShowInButton={"Agregar pine"}
              disabled={() => false}
              textToShow={this.getAddPineModalJSX()}
              handleOk={() => this.handleCreatePine()}
              okChildren={() => "Agregar pine"}
          />
        </div>
        <div style={bodyContainerStyle}>
          <TableContainer style={tableContainerStyle}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                      <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.pines.map((pine, key) => {
                  return (
                      <TableRow tabIndex={-1} key={key}>
                        {columns.map((column) => {
                          return (
                              <TableCell key={column.id} style={{padding: "0"}}>
                                {column.jsx(pine, this)}
                              </TableCell>
                          );
                        })}
                      </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  }
}

export default withRouter(PinesPage)
