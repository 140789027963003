import React from "react";
import {NotProposed} from "../../Coupon/NotProposed";
import {Proposed} from "../../Coupon/Proposed";
import {Recategorization} from "../../Coupon/Recategorization";
import {riseStrategies} from "../riseStrategies";

const riseSectionStyle = {
    display: "flex",
    flexDirection: "column",
    marginLeft: "4rem",
    width: "35rem"
};

export function RaiseProposalSection({handleBandChange,handleStrategyClick,selectedBand,selectedStrategy,pine,showRecategorization}) {
    const isRelocation = selectedStrategy.id === riseStrategies.RELOCATE.id;
    return <div style={riseSectionStyle}>
        <label className="label"> Elegir Estrategia</label>
        <NotProposed
            size='large'
            isSelected={selectedStrategy.id === riseStrategies.NO_RAISE.id}
            onClick={() => {handleStrategyClick(riseStrategies.NO_RAISE.id)}}
        />
        <Proposed
            size='large'
            isSelected={selectedStrategy.id === riseStrategies.STANDARD.id}
            bandState={{selectedBand, handleBandChange}}
            actualBand={pine.getBand()}
            onClick={() => {handleStrategyClick(riseStrategies.STANDARD.id)}}
            percentage={selectedBand.calculateRisePercentageToMe(pine.getOldRate())}
        />
        {showRecategorization && <Recategorization
            size='large'
            isSelected={isRelocation}
            onClick={() => {handleStrategyClick(riseStrategies.RELOCATE.id)}}
            bandState={{selectedBand, handleBandChange}}
            percentage={isRelocation ? selectedBand.calculateRisePercentageToMe(pine.getOldRate()) : null}
        />}
    </div>
}