export class Benefit {
    constructor(rawBenefitData) {
        this.name = rawBenefitData.nombre;
        this.value = rawBenefitData.valor;
        this.id = rawBenefitData.id;
    }

    getName() {
        return this.name;
    }

    getId() {
        return this.id;
    }

    getValue() {
        return this.value;
    }

}