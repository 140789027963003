import {Revision} from "../models/Revision";
import {getBackendApi} from "../api/BackendApiProvider";

export class RevisionsRepository {

  static getCurrentRevision(withPines =true) {
    return getBackendApi()
        .getCurrentRevision(withPines)
        .then(rawRevision => new Revision(rawRevision));
  }

  static getRevisions() {
    return getBackendApi()
        .getRevisions()
        .then(rawRevisions => rawRevisions.map(rawRevision => new Revision(rawRevision)))
        .then(revisions => _.orderBy(revisions,['date'], "desc"));
  }

  static getRevision(revision,withPines = true ) {
    return getBackendApi()
        .getRevision(revision.getId(),withPines)
        .then(rawRevision => new Revision(rawRevision));
  }

  static updateCurrentProposedPineId(pineId) {
    return getBackendApi()
        .updateCurrentProposedPineId(pineId);
  }

  static createAdjustmentRevision(adjustment, impactMonth,excludedPines){
    return getBackendApi().createAdjustmentRevision(adjustment, impactMonth,excludedPines);
  }

  static createExpressRevision(){
    return getBackendApi()
        .createExpressRevision()
        .then(rawRevision => new Revision(rawRevision));
  }

  static saveExpressRevision(revisionId, impactDate){
    return getBackendApi().saveExpressRevision(revisionId, impactDate)
  }

  static deleteRevision(revisionId) {
    return getBackendApi().deleteRevision(revisionId);
  }

  static isThereAnyOpenExpressRevision(revisions) {
    return revisions.some((revision) => revision.getIsExpress() && revision.getIsOpen());
  }

}
