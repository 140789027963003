import React from 'react'
import {faTree, faEye, faFileAlt} from "@fortawesome/free-solid-svg-icons";
import {FilterIcon} from "../sharedComponents/icons/FilterIcon";
import {individualPageFilters} from "./ProposalList";

const calculateColor = (activeFilter, filterName) => {
  return activeFilter.id === filterName.id ? "black" : "grey";
};

export const IndividualQuickFilters = props => (
    <div className={"quick-filters"}>
      <FilterIcon icon={faTree} borderColor={calculateColor(props.activeFilter, individualPageFilters.GARDENERS)} title={"Filtrar Jardinereados"} onClick={props.onGardenersFilterClick}/>
      <FilterIcon icon={faEye} borderColor={calculateColor(props.activeFilter, individualPageFilters.HIDE_PROPOSALS)} title={"Ocultar todas las propuestas"} onClick={props.onHideProposalsClick}/>
      <FilterIcon icon={faFileAlt} borderColor={calculateColor(props.activeFilter, individualPageFilters.PROPOSALS)} title={"Filtrar Pinos con Propuestas"} onClick={props.onFilterProposalsClick}/>
      <span className={props.activeFilter.id === individualPageFilters.NO_FILTER.id ? "" : "active"}> QUICK FILTERS </span>
    </div>
);
