import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const containerStyle = {
    marginRight: "1rem",
    display: "flex",
    height: "fit-content",
}

export const ExpressRevisionWarningIcon = () => {

    return (
        <div style={containerStyle} title={" Todas las propuestas subidas dentro de una Revisión Extraordinaria " +
        "serán confirmadas sin un proceso de revisión "}>
            <FontAwesomeIcon color={"goldenrod"} icon={faExclamationTriangle} size={"2x"}/>
        </div>
    )
}

export default ExpressRevisionWarningIcon;
