import {BandsRepository} from "../repositories/BandsRepository";
import {filter, take} from 'lodash';

export class Band {

  constructor(rawBandData) {
    this.name = rawBandData.nombre;
    this.floor = rawBandData.limiteInferior;
    this.id = rawBandData.id;
  }

  getName() {
    return this.name;
  }

  getId(){
    return this.id;
  }

  getFloor(){
    return this.floor;
  }

  calculateRisePercentageToMe(rate) {
    return (((this.getFloor()/rate) * 100 ) - 100).toFixed(1);
  }

  getNextBand() {
    const nextBands = this.getNextBands(1);
    return nextBands[0];
  }

  getNextBands(numberOfBands){
    const nextBands = filter(BandsRepository.getSortedBandsAsc(), band => band.floor > this.floor)
    return take(nextBands, numberOfBands)
  }

  isTheNextOf(anotherBand) {
    return this === anotherBand.getNextBand();
  }
}