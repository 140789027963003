import React, {useState} from "react";
import {PopUpButton} from "../sharedComponents/PopUpButton";
import {CSVLink} from "react-csv";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import ImpactMonthDropdown from "./ImpactMonthDropdown";
import {impactMonthOptions} from "../businessLogic/models/ImpactMonths";
import moment from "moment";


const labelStyle = {
    fontSize: "1.1rem"
}

const impactMonthSectionStyle = {
    display: "flex",
    alignItems: "center",
    margin: "1em 0em",
    padding: "0 1rem",
    borderRadius: "10px"
}


export const CloseRevisionButton = (props) => {

    const [impactMonthSelected, setImpactMonth] = useState(impactMonthOptions.actualMonth);


    const impactDate = () => {
        return impactMonthSelected.getDateForCsv(moment()).format('YYYY-MM-DD');
    }

    const getCSVData = () => Array.from(props.pinesToExport).map(pine => ([impactDate(), pine.juridicPersonId, 'OTRAS', props.getPineNewRate(pine)]));

    const renderCSVButton = () => <CSVLink style={{marginRight: "1rem"}}
                                           enclosingCharacter={''}
                                           asyncOnClick={true}
                                           onClick={(event, done) => {
                                               props.handleCloseRevision(impactDate())
                                                   .then(() => done())
                                                   .catch(() => done(false))
                                           }}
                                           filename={props.revisionTitle +impactDate()}
                                           data={getCSVData()}> Ok, Exportar a
        CSV </CSVLink>


    const getTextToShow = () => {
        return <div style={{display: "flex", flexDirection: "column"}}>
            {props.textToShow()}
            <div style={impactMonthSectionStyle}>
                <div style={{marginRight: "1rem", cursor: "pointer"}}
                     title={"Siempre se impacta sobre el primer dia del mes elegido. " +
                     "Si elige mes actual, la revision se vera en los sueldos del mes siguiente."}>
                    <FontAwesomeIcon color={"silver"} icon={faQuestionCircle} size={"2x"}/>
                </div>
                <span style={{...labelStyle, marginRight: "1rem", fontWeight: "200"}}> Mes en que se va a impactar: </span>
                <ImpactMonthDropdown selectedImpactMonth={impactMonthSelected}
                                     onImpactMonthChange={handleNewImpactMonthSelected}/>
            </div>
        </div>;

    }

    const handleNewImpactMonthSelected = (newImpact) => {
        setImpactMonth(newImpact);
    };

    return <React.Fragment>
        <PopUpButton disabled={props.shouldBeDisabled}
                     textToShow={getTextToShow()}
                     disabledTitle={props.disabledText}
                     textToShowInButton={props.buttonLabel || "Cerrar Revisión"} okChildren={() => renderCSVButton()}/>
    </React.Fragment>

}

export default CloseRevisionButton
