import React from 'react'

import {faChevronDown, faLock, faLockOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const getLockIconFor = (revision) => {
    if(revision.getIsOpen()) {
        return (<FontAwesomeIcon className={"icono revision-abierta"} icon={faLockOpen} />);
    } else {
        return (<FontAwesomeIcon className={"icono"} icon={faLock} />);
    }
};

export class RevisionsDropdown extends React.PureComponent {

    state = {showOptions: false};

    handleDropdownClick = () => {
        this.setState({showOptions: !this.state.showOptions})
    };

    handleRevisionSelection = (event) => {
      const selectedRevision = this.props.revisions[parseInt(event.currentTarget.id)];
      this.props.onRevisionSelection(selectedRevision);
      this.setState({showOptions: false});
    };

    render() {
        return (
            <div className={"dropdown-revisiones"}>
                <div className={`dropdown-header ${this.state.showOptions ? "opciones-desplegadas" : "" }`} onClick={this.handleDropdownClick}>
                    {getLockIconFor(this.props.revision)}
                    <h2 className={`titulo ${this.props.revision.getIsOpen() ? "revision-abierta" : ""}`}> {this.props.revision.getName()} </h2>
                    {this.props.revision.getIsExpress() && <span className={"revision-extraordinaria"}> Extraordinaria </span>}
                    <FontAwesomeIcon className={"chevron"} icon={faChevronDown} />
                </div>
                { this.state.showOptions &&
                    <div className={"opciones"}>
                        { this.props.revisions.map((revision, index) =>
                            <div className={"opcion"} id={index} key={index} onClick={this.handleRevisionSelection}>
                                {getLockIconFor(revision)}
                                <p className={`titulo ${revision.getIsOpen() ? "revision-abierta" : ""}`}> {revision.getName()} </p>
                                { revision.getIsExpress() && <span className={"revision-extraordinaria"}> Extraordinaria </span>}
                            </div>
                        )}
                    </div>
                }
            </div>
        );
    }
}
