import React from "react";
import Loading from "../sharedComponents/Loading";
import {BandsRepository} from "../businessLogic/repositories/BandsRepository";
import {RevisionsRepository} from "../businessLogic/repositories/RevisionsRepository";
import {PinesRepository} from "../businessLogic/repositories/PinesRepository";
import {BenefitsRepository} from "../businessLogic/repositories/BenefitsRepository"
import AdjustmentPage from "./AdjustmentPage";

class AdjustmentPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true};
    }

    componentDidMount() {
        this.initializeState()
    }

    initializeState = async () => {
        try {
            // Esto se ejecuta primero porque se cachea ya que es necesario para instanciar el resto de los objetos. Si no se hiciera asi, dichas instanciaciones deberían ser asincrónicas
            await BandsRepository.initialize();
            await BenefitsRepository.initialize();
            const currentRevision = await RevisionsRepository.getCurrentRevision(true);
            const revisions = await RevisionsRepository.getRevisions();
            const pines = currentRevision.pines;
            this.setState({
                pines: pines,
                currentRevision: currentRevision,
                isLoading: false,
                isThereAnyOpenExpressRevision: RevisionsRepository.isThereAnyOpenExpressRevision(revisions),
            });
        } catch (error) {
            this.setState({isLoading: false});
        }
    };

    render() {
        if (this.state.isLoading)
            return <Loading/>
        return <AdjustmentPage pines={this.state.pines}
                               isThereAnyOpenExpressRevision={this.state.isThereAnyOpenExpressRevision}/>;
    }
}

export default AdjustmentPageContainer
