import React from 'react';
import {Button} from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import {CallToActionButton} from "../interactions/CallToActionButton";
import {RaiseProposalSection} from "./RaiseProposalSection";
import {getRiseStrategyById, strategyFromPine} from "../riseStrategies";
import {Select} from "../Select";
import _ from "lodash";

export class ProposalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initializeState(props);
  }

  initializeState = (props) => ({
    sponsors: props.pine.getSponsorsNames(),
    selectedBand: props.pine.getProposalBand(),
    description: props.pine.getProposalDescription(),
    selectedStrategy: this.getInitialStrategy(props)
  });

  getInitialStrategy = (props) => {
    return strategyFromPine(props.pine);
  };

  handleSponsorChange = (event) => {
    this.setState({sponsors: event.target.value});
  };

  handleDescriptionChange = (event) => {
    this.setState({description: event.target.value});
  };

  handleBandChange = (selectedBand) => this.setState({selectedBand});

  handleStrategyClick = (id) => {
    this.setState({selectedStrategy: getRiseStrategyById(id)});
  };

  handleAccept = () => {
    const percentage = this.state.selectedStrategy.getPercentage(this.props.pine,this.state.selectedBand);
    const selectedBand = this.state.selectedStrategy.getProposedBand(this.props.pine,this.state.selectedBand);
    return  this.props.onAddProposal({ percentage, selectedBand,
      pine: this.props.pine,
      sponsors: this.state.sponsors,
      description: this.state.description
    });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  allPinesSortedAlphabetically = () =>
    _.sortBy(this.props.allPines, ['name'])

  render() {
    return <>
      <div className="propuesta-container">
        <div className="descripcion-container">
          <label className="label"> Descripción </label>
          <TextField
            label={"Ingresá la descripción acá.. "}
            value={this.state.description}
            onChange={this.handleDescriptionChange}
            multiline
            minRows={20}
            maxRows={20}
            variant="filled"
          />
        </div>
        {
          this.props.revisionData.getIsOpen() &&
            <RaiseProposalSection
                pine={this.props.pine}
                handleBandChange={this.handleBandChange}
                handleStrategyClick={this.handleStrategyClick}
                selectedBand={this.state.selectedBand}
                selectedStrategy={this.state.selectedStrategy}
                showRecategorization={this.props.revisionData.isExpress}
            />
        }
        <div className="columna">
          <div className="columna" style={{ height: "30%"}}>
            <label className="label"> Sponsors </label>

            <Select
                value={this.state.sponsors}
                style={{borderRadius: "0px"}}
                multiple
                onChange={this.handleSponsorChange}
                options={this.allPinesSortedAlphabetically()}
            />
          </div>
          {this.props.pine.getReviewComments() !== "" && <div className="columna" style={{height: "70%"}}>
            <label className="label"> Comentarios de la revisión </label>
            <TextField
                id="filled-textarea"
                value={this.props.pine.getReviewComments()}
                multiline
                disabled
                minRows={10}
                maxRows={10}
                variant="filled"
            />
          </div>}
        </div>
      </div>
      <div className="acciones-propuesta">
        <Button className="cancelar"
                variant={"outlined"}
                onClick={this.handleCancel}>
          Cancelar
        </Button>
        {!this.props.readOnly &&
        <CallToActionButton isDisabled={!this.props.revisionData.getIsOpen()} onClick={this.handleAccept}
                            label={"Aceptar"}/>
        }
      </div>
    </>;
  }
}
