import React from 'react'
import {Icon} from "./Icon";

const containerStyle = {
    cursor: "pointer"
};

export class FilterIcon extends React.Component {

    state = {isEnabled: false};

    handleClick = () => {
        const newStatus = !this.state.isEnabled;
        this.setState({isEnabled: newStatus});
        this.props.onClick(newStatus);
    };

    render() {
        return (
          <div style={containerStyle} title={this.props.title} onClick={this.handleClick}>
              <Icon icon={this.props.icon} borderColor={this.props.borderColor}/>
          </div>
        );
    }
}
