import React from 'react'
import {ProposalReview} from "./ProposalReview";
import RevisionImpactPercentage from "./RevisionImpactPercentage";
import {CloseRevisionButton} from "./CloseRevisionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";

const warningContainerStyle = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "1rem",
  padding: "1rem",
  borderRadius: "10px",
  alignItems: "center",
}

const warningMessageStyle = {
  fontWeight: "200",
  marginLeft: "1rem"
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  height: "100%"
};

const getCurrentProposedPine = (pines, revisionData) => {
  return pines.find(pine => pine.getId() === revisionData.currentProposedPineId);
};

export class ProposalsReviewPage extends React.Component {

  textToShowOnPopUp = () => {
    const pinesOnHold = this.props.pines.filter(pine => pine.getProposal().isProposalOnHold())
    return pinesOnHold.length > 0 &&
    <div style={warningContainerStyle}>
      <FontAwesomeIcon icon={faExclamationCircle} color={"goldenrod"} size={"2x"}/>
      <span style={warningMessageStyle}>{`Hay todavia  
                    ${pinesOnHold.length} ${pinesOnHold.length === 1 ? "propuesta" : "propuestas"} on hold. 
                    Estas propuestas no se considerarán!`}</span>
    </div>
  }

  shouldDisableCloseRevision= () => {
      return !this.props.pines.every((pine) => !pine.proposal.isProposalNotTreated()) ||
          this.props.isThereAnyOpenExpressRevision;
  }

  disabledTextCloseRevision = () => {
    return this.props.isThereAnyOpenExpressRevision ?
        "No se puede cerrar una revisión habiendo una revisión extraordinaria abierta"
        :
        "No se puede cerrar una revisión con propuestas no tratadas";
  }

  render() {
    const preconfirmedPines = this.props.pines.filter(pine => pine.hasAProposalThatChangeRate())
    return (
      <div style={containerStyle}>
        <div style={{padding: "2em", background: "white", borderRadius: "30px 30px 0 0",
          display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <span style={{fontSize: "1.8rem", fontWeight: "200"}}> {this.props.revisionData.getName()} </span>
          <div style={{display: "flex", alignItems: "center"}}>
            <RevisionImpactPercentage  revision={this.props.revisionData}/>
            <CloseRevisionButton
                handleCloseRevision={this.props.onCloseRevision} pines={this.props.pines}
                pinesToExport={preconfirmedPines} textToShow={this.textToShowOnPopUp}
                shouldBeDisabled={() => this.shouldDisableCloseRevision()}
                disabledText={this.disabledTextCloseRevision()}
                revisionTitle={"Revision de rates - "}
                getPineNewRate={(pine) => pine.getNewRateOfPine()}
            />
          </div>
        </div>
        <ProposalReview key={this.props.revisionData.currentProposedPineId}
                        proposedPine={getCurrentProposedPine(this.props.pines, this.props.revisionData)}
                        pines={this.props.pines}
                        updateRevision={this.props.updateRevision}
                        replacePine={this.props.replacePine}
                        revisionData={this.props.revisionData}
                        handleFoldingProposalReview={this.handleFoldingProposalReview}
        />
      </div>
    );
  }
}
