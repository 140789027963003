import React from 'react'
import logo from '10Pines-pino-en-circulo.svg'
import BackgroundImage from "images/background_login.jpg";

const urlDeInicioOAuth = "/auth/backoffice";

const containerStyle = {
  height: "100%",
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "url('" + BackgroundImage + "') no-repeat center center fixed",
  backgroundSize: "cover",
  flexDirection: "column"
};

const signUpMenuStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "33rem",
  borderTop: "2px solid white",
  borderRadius: "10px",
  background: "rgb(0, 0, 0, 0.85)",
  paddingTop: "2rem",
  paddingBottom: "2rem",
  marginBottom: "10rem"
};

const titleStyle = {
  color: "white",
  fontSize: "2rem",
};

const logoContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center"
};

const logoStyle = {
  height: "3rem",
  marginTop: "3rem"
};

const ratesTextStyle = {
  fontFamily: "'Euphoria Script', cursive",
  color: "white",
  fontSize: "14rem",
  marginLeft: "1rem",
};

const linkStyle = {
  textDecoration: "none",
  marginTop: "1rem",
  backgroundColor: "white",
  fontColor: "black",
  width: "20rem",
  padding: "0.8rem",
  border: "1px solid black",
  borderRadius: "20px",
  textAlign: "center",
  cursor: "pointer"
};

export class SignUp extends React.Component {

  render() {
    return (
      <div style={containerStyle}>
        <div style={logoContainerStyle}>
          <img style={logoStyle} src={logo} alt={"Logo"}/>
          <span style={ratesTextStyle}> Rates </span>
        </div>
        <div style={signUpMenuStyle}>
          <span style={titleStyle}>Ingresá con el <b>Backoffice</b></span>
          <form action={urlDeInicioOAuth} method="post">
            <input type="hidden" name="authenticity_token" value={document.querySelector('[name=csrf-token]').content}/>
            <button style={linkStyle} type="submit">LOG IN</button>
          </form>
        </div>
      </div>
    );
  }
}
