import React from 'react'
import ReactDOM from 'react-dom'
import {SignUp} from "./SignUp";
import {App} from "../components/App";

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <SignUp/>,
    document.body.appendChild(document.createElement('div')),
  )
});
