import React from "react";
import Loading from "../sharedComponents/Loading";
import {RevisionsRepository} from "../businessLogic/repositories/RevisionsRepository";
import PinesPage from "./PinesPage";

class PinesPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true};
    }

    componentDidMount() {
        this.initializeState()
    }

    initializeState = async () => {
        try {
            // Esto se ejecuta primero porque se cachea ya que es necesario para instanciar el resto de los objetos. Si no se hiciera asi, dichas instanciaciones deberían ser asincrónicas
            const currentRevision = await RevisionsRepository.getCurrentRevision(true);
            const pines = currentRevision.pines;
            this.setState({
                pines: pines,
                currentRevision: currentRevision,
                isLoading: false,
            });
        } catch (error) {
            this.setState({isLoading: false});
        }
    };

    render() {
        if (this.state.isLoading)
            return <Loading/>
        return <PinesPage pines={this.state.pines}/>;
    }
}

export default PinesPageContainer
