import React from 'react'
import {
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@material-ui/core/TextField";
import {ProposalsRepository, proposalStatuses} from "../businessLogic/repositories/ProposalsRepository";
import {Spinner} from "../sharedComponents/Spinner";
import {CallToActionButton} from "../sharedComponents/interactions/CallToActionButton";
import NearPines from "./NearPines";
import {WeakSponsorThumbsUpReadOnly} from "../proposalsPage/WeakSponsorThumbsUp";
import ProposalDropdown from "../sharedComponents/pines/ProposalDropdown";
import {Revision} from "../businessLogic/models/Revision";
import {PineLastRisesChart} from "../sharedComponents/pines/PineLastRisesChart";
import {Coupon} from "../Coupon/Coupon";

const basicContainerStyle = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const containerStyle = {
  ...basicContainerStyle,
  overflow: "hidden",
};

const titleSectionStyle = {
  marginLeft: "2rem",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "1rem",
  marginRight: "2rem",
  justifyContent: "space-between"
};

const bodySectionStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  height: "19rem",
  width: "100%",
  marginBottom: "2rem",
};

const proposalSectionStyle = {
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
};

const reviewSectionStyle = {
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "30rem",
  height: "100%",
  paddingLeft: "3rem",
};

const decisionSectionStyle = {
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  width: "20rem",
  paddingLeft: "2rem",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
};

const textFieldStyle  = {
  background: "silver",
  marginBottom: "1rem",
};

const decisionIconsSectionStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: "1rem",
};

const adjustProposalSectionStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center"
};

export class ProposalReview extends React.Component {
  state = {
    isLoading: false,
    adjustedPercentage: this.props.proposedPine.getProposalPercentage(),
    adjustedRate: this.props.proposedPine.getProposedRate(),
    reviewComments: this.props.proposedPine.getProposal().getReviewComments(),
    selectedDecision: this.props.proposedPine.getProposalStatus()
  };

  isInvalidProposal = () => {
    return !this.props.revisionData.getIsOpen()
        || ProposalsRepository.isInInvalidDecisionForSavingProposal(this.state.selectedDecision);
  };

  updateRevision = revision => {
    this.setState({isLoading: false})
    this.props.updateRevision(new Revision(revision))
  };
  onChangeProposedPine = (pine) => {
    this.setState({isLoading:true})
    this.props.revisionData.setCurrentProposedPineId(pine.id)
        .then(this.updateRevision);
  };
  onNextProposalReview = (pine) => {
    this.props.revisionData.nextProposalReview(pine, this.props.pines)
        .then(this.updateRevision);
  };
  onProposalSave = (pine) => {
    return ProposalsRepository.createProposal({...pine.getRawProposalData(), revisionId: this.props.revisionData.getId()}).then(_ => {
      this.props.revisionData.nextProposalReview(pine, this.props.pines).then(revision => {
        this.props.replacePine(pine, "Propuesta actualizada!");
        this.updateRevision(revision)
      });
    });
  };
  onPreviousProposalReview = (pine) => {
    this.props.revisionData.previousProposalReview(pine, this.props.pines).then(this.updateRevision);
  };


  getPineWithNewValues = () => {
    const newProposal = {
      ...this.props.proposedPine.getProposal().getRawProposalData(),
      "comentariosRevision": this.state.reviewComments,
      "status": this.state.selectedDecision.backendName,
      "rateRevision": this.state.adjustedRate,
      "porcentajeRevision": this.state.adjustedPercentage
    };

    this.props.proposedPine.setRawProposal(newProposal);

    return this.props.proposedPine;
  };

  handleReviewCommentsChange = (event) => {
    this.setState({reviewComments: event.target.value});
  };

  isLastProposedPine = () => {
    const proposedPines = this.props.pines.filter(pine => pine.hasProposal());
    return this.props.proposedPine.getId() === proposedPines[proposedPines.length - 1].getId();
  };

  isFirstProposedPine = () => {
    const proposedPines = this.props.pines.filter(pine => pine.hasProposal());
    return this.props.proposedPine.getId() === proposedPines[0].getId();
  };

  handleNextClick = () => {
    if(!this.isLastProposedPine()) {
      this.setState({isLoading: true});
      this.onNextProposalReview(this.props.proposedPine);
    }
  };

  handlePreviousClick = () => {
    if(!this.isFirstProposedPine()){
      this.setState({isLoading: true});
      this.onPreviousProposalReview(this.props.proposedPine);
    }
  };

  handleProposalSave = () => {
    return this.onProposalSave(this.getPineWithNewValues());
  };

  isProposalAdjusted = (adjustedPercentage, adjustedRate) => {
    return adjustedPercentage !== this.props.proposedPine.getProposal().getPercentage() || adjustedRate !== this.props.proposedPine.getProposal().getRate();
  };

  handleCheckClick = () => {
    if(this.props.revisionData.getIsOpen()){
      if(!this.isProposalAdjusted(this.state.adjustedPercentage, this.state.adjustedRate)) {
        this.setState({selectedDecision: this.state.selectedDecision !== proposalStatuses.PRE_CONFIRMED ? proposalStatuses.PRE_CONFIRMED : proposalStatuses.NOT_TREATED});
      }
    }
  };

  handleExclamationClick = () => {
    if(this.props.revisionData.getIsOpen()){
      if(!this.isProposalAdjusted(this.state.adjustedPercentage, this.state.adjustedRate)) {
        this.setState({selectedDecision: this.state.selectedDecision !== proposalStatuses.ON_HOLD ? proposalStatuses.ON_HOLD : proposalStatuses.NOT_TREATED});
      }
    }
  };

  getExclamationIconColor = () => {
    return this.state.selectedDecision === proposalStatuses.ON_HOLD ? proposalStatuses.ON_HOLD.enabledColor : "lightgrey"
  };

  getCheckIconColor = () => {
    return this.state.selectedDecision === proposalStatuses.PRE_CONFIRMED ? proposalStatuses.PRE_CONFIRMED.enabledColor : "lightgrey"
  };

  handlePercentageAdjustment = (event) =>{
    const selectedDecision = this.isProposalAdjusted(event.target.value, this.state.adjustedRate) ? proposalStatuses.PRE_CONFIRMED : this.state.selectedDecision;
    this.setState({adjustedPercentage: event.target.value, adjustedRate: this.getRateFromPercentage(event.target.value), selectedDecision: selectedDecision});
  };

  handleRateAdjustment = (event) =>{
    const selectedDecision = this.isProposalAdjusted(this.state.adjustedPercentage, event.target.value) ? proposalStatuses.PRE_CONFIRMED   : this.state.selectedDecision;
    this.setState({adjustedRate: event.target.value, adjustedPercentage: this.getPercentageFromAdjustedRate(event.target.value), selectedDecision: selectedDecision});
  };

  getRateFromPercentage = (newPercentage) => {
    return (this.props.proposedPine.getOldRate() * (newPercentage / 100 + 1)).toFixed(2);
  };

  getPercentageFromAdjustedRate = (newRate) => {
    return (((newRate / this.props.proposedPine.getOldRate() ) * 100 ) - 100).toFixed(1);
  };

  render() {
    if (this.state.isLoading) {
      return <div style={{...basicContainerStyle, height: "100%", width: "100%"}}><Spinner/></div>
    }
    return (
        <div style={containerStyle} key={this.props.pines}>
          <div style={titleSectionStyle}>
            <div style={{display: "flex", alignItems: "center"}}>
              <ProposalDropdown onChangeProposedPine={this.onChangeProposedPine} pines={this.props.pines}
                                proposedPine={this.props.proposedPine} revision={this.props.revisionData}/>
              <WeakSponsorThumbsUpReadOnly style={{paddingLeft: "1rem"}} pine={this.props.proposedPine}/>
            </div>
          </div>
          <div style={{...bodySectionStyle, height: "100%", alignItems: "center"}}>
            <div style={{marginLeft: "1rem", opacity: this.isFirstProposedPine() ? "0.1" : "1", cursor: this.isFirstProposedPine() ? null : "pointer"}} onClick={this.handlePreviousClick}>
              <FontAwesomeIcon icon={faChevronCircleLeft} color={"lightgrey"} size={"3x"}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", width: "100%"}}>
              <div style={bodySectionStyle}>
                <div style={proposalSectionStyle}>
                  <Coupon pine={this.props.proposedPine} size={'medium'}/>

                  <TextField style={{...textFieldStyle, marginTop: "1rem"}} readOnly id="filled-basic" label="Sponsors" variant="filled" value={this.props.proposedPine.getSponsorsNames().join(", ")} />
                  <TextField style={textFieldStyle} readOnly id="filled-basic" label="Jardineres" variant="filled" value={this.props.proposedPine.getGardenersNames().join(", ")} />
                  <TextField style={textFieldStyle} readOnly multiline maxRows={15} minRows={8} id="filled-basic" label="Descripción" variant="filled" value={this.props.proposedPine.getProposalDescription()} />
                </div>
                <div style={reviewSectionStyle}>
                  <NearPines pines={this.props.pines} proposedPine={this.props.proposedPine}/>
                </div>
                <div style={decisionSectionStyle}>
                  <div style={decisionIconsSectionStyle}>
                    <FontAwesomeIcon style={{marginRight: "3rem", cursor: "pointer"}} icon={faCheckCircle} color={this.getCheckIconColor()} size={"3x"} onClick={this.handleCheckClick}/>
                    <FontAwesomeIcon style={{cursor: "pointer"}} icon={faExclamationCircle} color={this.getExclamationIconColor()} size={"3x"} onClick={this.handleExclamationClick}/>
                  </div>
                  <TextField
                      style={{width: "100%", backgroundColor: "white"}}
                      id="filled-textarea"
                      label={"Comentarios de la revisión acá.. "}
                      value={this.state.reviewComments || ''}
                      onChange={this.handleReviewCommentsChange}
                      multiline
                      minRows={6}
                      maxRows={6}
                      variant="filled"
                  />
                  <div style={{marginTop: "1rem", display: "flex", width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-around"}}>
                    <div style={adjustProposalSectionStyle}>
                      <div style={{display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center"}}>
                        <span style={{fontWeight: "200", fontSize: "1.1rem", marginBottom: "0.5rem"}}> Ajustar propuesta </span>
                        <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "space-around"}}>
                          <TextField
                              style={{backgroundColor: "white", width: "4rem"}}
                              id="filled-textarea"
                              label={"%"}
                              value={this.state.adjustedPercentage}
                              onChange={this.handlePercentageAdjustment}
                              variant="filled"
                          />
                          <TextField
                              style={{backgroundColor: "white", width: "4rem"}}
                              id="filled-textarea"
                              label={"$"}
                              value={this.state.adjustedRate}
                              onChange={this.handleRateAdjustment}
                              variant="filled"
                          />
                        </div>
                      </div>
                    </div>
                    <CallToActionButton isDisabled={this.isInvalidProposal()} label={"Guardar"} onClick={this.handleProposalSave}/>
                  </div>
                </div>
              </div>
              <PineLastRisesChart pine={ this.props.proposedPine } width={"550"} height={"250"}/>
            </div>
            <div style={{marginRight: "1rem", opacity: this.isLastProposedPine() ? "0.1" : "1", cursor: this.isLastProposedPine() ? "null" : "pointer"}} onClick={this.handleNextClick}>
              <FontAwesomeIcon icon={faChevronCircleRight} color={"lightgrey"} size={"3x"}/>
            </div>
          </div>
      </div>
    );
  }
}
