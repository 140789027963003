import React from 'react'
import {Coupon} from "../../Coupon/Coupon";

const divStyle = (isTherePercentage) => ({
  background: "lightgrey",
  width: "4rem",
  maxWidth: "4rem",
  height: "3rem",
  borderRadius: "30%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "2rem",
});

const textStyle = (isTherePercentage) => ({
  color: isTherePercentage ? "black" : "grey",
  fontSize: "1.1rem"
});

const isTherePercentage = (props) => {
  return props.percentage != null || props.pine.hasProposal();
};

const calculateRiseLabel = (props) => {
    return isTherePercentage(props) ? (props.percentage || props.pine.getProposalPercentage())  + "%" : "N/A";
};

export const RiseSquare = (props) => {
    if(props.isAdjustment){
        return <PercentageSquare percentage={calculateRiseLabel(props)}/>
    }
    else
        return <Coupon pine={props.pine} percentage={props.percentage} size='compact'/>
};

export function PercentageSquare({percentage}){
    return <div style={divStyle(percentage != null)}>
        <span style={textStyle(percentage != null)}> {percentage || 'N/A'} </span>
    </div>
}
