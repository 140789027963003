import React from "react";
import {PinesRepository} from "./businessLogic/repositories/PinesRepository";

export const {Consumer,Provider} = React.createContext(
    {loggedPine: null}
);

class AuthContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loggedPine: null};
    }

    componentDidMount() {
        if (!this.state.loggedPine){
            PinesRepository.getLoggedPine()
                .then((loggedPine) => {
                    this.setState({loggedPine});
                })
        }
    }

    render() {
        const {children} = this.props;
        return <Provider value={{loggedPine: this.state.loggedPine}}>
            {children}
        </Provider>;
    }
}

export function withAuthContext(Component) {
    return function WrapperComponent(props) {
        return (
            <Consumer>
                {context => <Component {...props} loggedPine={context.loggedPine} />}
            </Consumer>
        );
    };
}
export default AuthContainer;
