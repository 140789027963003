import React from "react";

import Button from "@material-ui/core/Button";
import ExpressRevisionWarningIcon from "./ExpressRevisionWarningIcon";
import {CloseRevisionButton} from "../revisionsPage/CloseRevisionButton";

const containerStyle = {
    marginRight: "1em"
}

const buttonsContainerStyle = {
    display: "flex",
    alignItems: "center"
}

export const ExpressRevisionButton = (props) => {

    const shouldDisableCloseRevision = () => {
        return !props.pines.some((pine) => pine.hasAProposalThatChangeRate())
    }

    const confirmedPines = props.pines.filter(pine => pine.hasAProposalThatChangeRate())

    return <div style={containerStyle}>
        { !props.isExpressRevisionOpen ?
            <Button variant="outlined" color="primary" onClick={() => props.onExpressRevisionCreate()}> Crear Revisión Extraordinaria </Button>
            :
            <div style={buttonsContainerStyle}>
                <ExpressRevisionWarningIcon/>
                <CloseRevisionButton
                    buttonLabel={"Guardar Revisión Extraordinaria"}
                    handleCloseRevision={ props.onExpressRevisionSave } pines={props.pines}
                    pinesToExport={confirmedPines} textToShow={() => ""}
                    shouldBeDisabled={() => shouldDisableCloseRevision()}
                    disabledText={"No se puede cerrar una revisión extraordinaria sin propuestas"}
                    revisionTitle={"Revision de rates Extraordinaria- "}
                    getPineNewRate={(pine) => pine.getNewRateOfPine()}
                />
                <Button style={{marginLeft: "1rem"}} variant={"outlined"} onClick={props.onExpressRevisionDelete}> Descartar Cambios </Button>
            </div>
        }
    </div>

}

export default ExpressRevisionButton;
