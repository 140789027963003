import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const iconStyle = {
  marginRight: "1rem",
  marginLeft: "1rem"
};

export class ProposalStatusIcon extends React.Component {

  render() {
    return (
      <div title={ this.props.pine.getProposalStatus().name } className={"proposal-status-icon"}>
        { (this.props.pine.getProposal().isProposalPreConfirmed() || this.props.pine.getProposal().isProposalAccepted()) && <FontAwesomeIcon style={iconStyle} icon={this.props.pine.getProposalStatus().icon} color={this.props.pine.getProposalStatus().enabledColor} size="2x"/>}
        { this.props.pine.getProposal().isProposalOnHold() && <FontAwesomeIcon style={iconStyle} icon={this.props.pine.getProposalStatus().icon} color={this.props.pine.getProposalStatus().enabledColor} size="2x"/>}
        { this.props.pine.getProposal().isProposalNotTreated() && <FontAwesomeIcon style={iconStyle} icon={this.props.pine.getProposalStatus().icon} color={this.props.pine.getProposalStatus().enabledColor} size="2x"/> }
        { this.props.pine.getProposal().isNotCompleted() && <FontAwesomeIcon style={iconStyle} icon={this.props.pine.getProposalStatus().icon} color={this.props.pine.getProposalStatus().enabledColor} size="2x"/> }
      </div>
    );
  }
};