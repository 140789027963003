import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import RawSelect from "@material-ui/core/Select";
import React from "react";

export const selectStyle = {
    backgroundColor: "white",
    padding: "0.3rem",
    borderRadius: "10px"
};

const chipsStyle = {
    display: 'flex',
    flexWrap: 'wrap'
};

const chipStyle = {
    margin: 2,
    backgroundColor: "silver",
};

const MenuProps = {
    PaperProps: {
        style: {
            width: 250,
        },
    },
};

export function Select({value,onChange,options,style,multiple,disabled = false}) {

    const multipleProps = multiple ?
    {
        renderValue: (selected) => (
        <div style={chipsStyle}>
            {selected.map(value => (
                <Chip key={value} label={value} style={chipStyle}/>
            ))}
        </div>),
        multiple:true,
    }
    : undefined
   return <RawSelect
           style={{...selectStyle, ...style}}
           value={value}
           onChange={onChange}
           input={<Input/>}
           MenuProps={MenuProps}
           disabled={disabled}
           {...multipleProps}
       >
           {options.map(option => (
               <MenuItem key={option.getId()} value={option.getName()} id={option.getId()}>
                   {option.getName()}
               </MenuItem>
           ))}
       </RawSelect>
}