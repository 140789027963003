import {Proposal} from "./Proposal";
import {proposalStatuses} from "../repositories/ProposalsRepository";
import {BandsRepository} from "../repositories/BandsRepository";
import {formatDateToMonthYear} from "../../sharedComponents/commons";
import {getBackendApi} from "../api/BackendApiProvider";

export class Pine {

  constructor(rawPineData) {
    this.proposal = new Proposal(rawPineData.propuesta);
    this.id = rawPineData.id;
    this.name = rawPineData.nombre;
    this.band = BandsRepository.getBandByName(rawPineData.franja);
    this.rate = rawPineData.rate;
    this.salary = rawPineData.bruto;
    this.gardeners = rawPineData.jardineros;
    this.lastRises = rawPineData.ultimosAumentos || [];
    this.startDate = rawPineData.fechaIngreso;
    this.lastRiseDate= rawPineData.fechaUltimoAumento;
    this.juridicPersonId = rawPineData.idPersonaJuridica;
    this.deletedAt = rawPineData.deleted_at;
    this.rawPineData = rawPineData;
  }
  
  getName() {
    return this.name;
  }

  getId() {
    return this.id
  }

  hasGardeners() {
    return this.getGardeners().length > 0
  };

  getGardeners() {
    return this.gardeners
  }

  getGardenersNames() {
    return this.getGardeners().map(gardener => gardener["nombre"])
  }

  isPineGardener(gardener) {
    return this.getGardeners().map(g => g["id"]).includes(gardener["id"])
  }

  getRawBasicData() {
    return {"id": this.getId(), "nombre": this.getName() };
  }

  addGardener(gardener) {
    return getBackendApi().addGardener(this.getId(), gardener.getId()).then(() => this.gardeners.push(gardener.getRawBasicData()));
  }

  updateGardenersByName(pines, gardenerNames) {
    const gardeners = gardenerNames.map((name) => pines.find((pine) => pine.getName() === name));
    return this.updateGardeners(gardeners);
  }

  updateGardeners(gardeners) {
    const gardenersIds = gardeners.map((gardener) => gardener.getId())
    return getBackendApi().updateGardeners(this.getId(), gardenersIds)
        .then(() => this.gardeners = gardeners.map((gardener) => gardener.getRawBasicData()));
  }

  delete(optionalDate){
    return getBackendApi().deletePine(this.getId(), optionalDate)
        .then((response) => this.deletedAt = response.deleted_at);
  }

  removeGardener(gardener) {
    return getBackendApi().removeGardener(this.getId(), gardener.getId()).then(() => this.gardeners = this.gardeners.filter(g => g["id"] !== gardener.getId()));
  }

  getRises() {
    return getBackendApi().getPineLastRises(this.getId());
  }

  hasProposal() {
    return !this.getProposal().isNotCompleted()
  };
  
  getProposal(){
    return this.proposal;
  }

  getSponsors() {
    return this.getProposal().getSponsors()
  }

  getSponsorsNames() {
     return this.getProposal().getSponsorsNames();
  }

  getProposalBand() {
    return this.getProposal().getBand();
  }

  getProposalDescription() {
    return this.getProposal().getDescription();
  }

  getProposalPercentage() {
    return this.getProposal().getReviewPercentage() || this.getProposal().getPercentage();
  }

  getOldRate() {
    return this.rate;
  }
  getOldSalary() {
    return this.salaryFromRate(this.getOldRate());
  }

  getBand() {
    return this.band;
  }

  getSalary() {
    return this.salary;
  }

  getLastRiseDate(){
    return this.lastRiseDate;
  }

  isChangingBand() {
    return this.getBand().getName() !== this.getProposalBand().getName();
  }

  setRawProposal(rawProposalData) {
    this.proposal = new Proposal(rawProposalData);
    this.rawProposalData = this.proposal.getRawProposalData();
  }

  initializeProposal() {
    this.setRawProposal(this.getProposalNullObject());
  }

  addWeakSponsor(pineSponsor) {
    return this.getProposal().addWeakSponsor(pineSponsor).then(() => this);
  }

  isPineWeakSponsor(pine){
    return this.getProposal().getWeakSponsors().find(sponsor => sponsor.id === pine.getId());
  }

  amountOfWeakSponsors(pine){
    return this.getProposal().getAmountOfWeakSponsors();
  }

  removeWeakSponsor(pineSponsor) {
    return this.getProposal().removeWeakSponsor(pineSponsor).then(() => this);
  }

  getProposalStatus() {
    return this.getProposal().getStatus();
  }

  getRiseByDate(date) {
    return this.getRises().find(rise => rise["fecha"] === date).then(res => res.data);
  };

  //TODO: Refactor this quick fix
  getOldProposalValueByKey(key) {
    if(key === "fechaUltimoAumento") {
      return formatDateToMonthYear(this.rawPineData[key]);
    } else {
      return this.rawPineData[key];
    }
  }

  getRawProposalData() {
    return {...this.getProposal().getRawProposalData(), pino: this.getId()};
  }

  getProposalValueByKey(key) {
    if(key === "fechaUltimoAumento") return formatDateToMonthYear(this.getLastSalaryRiseDate());
    if(key === "rate") return this.getMostUpdatedRate();
    if(key === "bruto") {return this.getMostUpdatedSalary()}
    else {
      return this.proposal.getRawProposalData()[key];
    }

  }

  getProposedRate(){
    return this.getProposal().getProposedRate();
  }

  getMostUpdatedSalary() {
    return this.salaryFromRate(this.getMostUpdatedRate());
  }

  getMostUpdatedRate(){
    return this.getProposedRate() || this.getOldRate();
  }

  getRawData(){
    return this.rawPineData;
  }

  salaryFromRate(rate){
    const monthHours = 8 * 22;
    return rate * monthHours;
  }

  calculateNewRateFromPercentage(percentage) {
    return Math.ceil(this.getOldRate() * (1 + percentage / 100));
  }

  addProposal(sponsors, percentage, newBand, description, revision) {
    const newRate = percentage === 0 ? this.rate : newBand.getFloor();
    const newSalary = this.salaryFromRate(newRate);
    const newLastRiseDate = percentage === 0 ? this.getLastRiseDate() : revision.getMonthYearFormattedDate();
    const newRawProposal = {
      revisionId: revision.getId(),
      fecha: new Date(),
      porcentaje: percentage,
      descripcion: description,
      franja: newBand.getName(),
      sponsors: sponsors,
      sponsorsDebiles: this.getProposal().getWeakSponsors(),
      rate: newRate,
      bruto: newSalary,
      fechaUltimoAumento: newLastRiseDate,
      status: proposalStatuses.NOT_TREATED.backendName,
    };
    return getBackendApi().createProposal({...newRawProposal, pino: this.getId()}).then((response) => {
      this.setRawProposal(response);
    });
  }

  isTheSameProposal(sponsorNames, percentage, band, description) {
    return this.hasThisSponsorNamesExactly(sponsorNames) && percentage === this.getProposalPercentage() && this.getProposalBand().getName() === band.getName() && description === this.getProposalDescription();
  }

  hasThisSponsorNamesExactly(sponsorNames) {
    return sponsorNames.length === this.getSponsors().length && this.getSponsors().every(sponsor => sponsorNames.includes(sponsor["nombre"]));
  }

  getReviewComments() {
    return this.getProposal().getReviewComments();
  }

  getProposalNullObject() {
    return {
      sponsorsDebiles: [],
      fecha: new Date(),
      porcentaje: null,
      descripcion: "",
      franja: this.getBand().getName(),
      sponsors: [],
      rate: this.getOldRate(),
      bruto: this.getSalary(),
      fechaUltimoAumento: this.getLastRiseDate(),
      status: proposalStatuses.NOT_COMPLETED.backendName
    }
  }

  hasPreconfirmedProposal() {
    return this.getProposal().isProposalPreConfirmed()
  }

  getLastSalaryRiseDate() {
    if (this.proposal.getPercentage() == "0") {
      return this.lastRiseDate;
    }
    return this.proposal.getRawProposalData()["fecha"];
  }

  hasAProposalThatChangeRate() {
    return (this.hasPreconfirmedProposal() || this.proposal.isProposalAccepted()) && this.getProposalPercentage() !== 0;
  }

  getNewRateOfPine() {
    if (this.proposal.isProposalAccepted() || this.proposal.isProposalPreConfirmed()) {
      return this.getMostUpdatedRate()
    }
    return this.getOldRate();
  };
}

export const create = (newPine) => {
  return getBackendApi().createPine(newPine).then(rawPine => new Pine(rawPine.data))
}
