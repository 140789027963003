import React from 'react'

const revisionImpactPercentageContainerStyle = {
    display: "flex",
    flexDirection: "row",
    marginRight: "2rem",
}

const textStyle = {
    fontWeight: "200",
    fontSize: "1.2rem",
    marginRight: "1rem"
}

const percentageStyle = {
    fontWeight: "700",
    fontSize: "1.2rem"
}

const RevisionImpactPercentage = ({revision}) =>  {
    return (
        <div style={revisionImpactPercentageContainerStyle}>
            <span style={textStyle}> Impacto en la masa salarial: </span>
            <span style={percentageStyle}> {revision.impactSalaryPercentage().toFixed(2)} %</span>
        </div>
    );
}
export default RevisionImpactPercentage;
