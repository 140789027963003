import React from 'react'
import Dropdown from "../sharedComponents/Dropdown";
import impactMonthOptions from "../businessLogic/models/ImpactMonths";
const titleFontStyle = {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    textAlign: "center"
}
export function ImpactMonthDropdown(props) {
    const onRenderSelectedOption = (month) => {
        return <React.Fragment>
            <span style={titleFontStyle}> {month.name} </span>
        </React.Fragment>
    }

    const onShowOption = (month) => {
        return <React.Fragment>
            <span> {month.name} </span>
        </React.Fragment>
    }


    return (<Dropdown options={impactMonthOptions} onSelected={props.onImpactMonthChange} selected={props.selectedImpactMonth}
                      onRenderSelectedOption={onRenderSelectedOption} onShowOption={onShowOption} width={"10rem"}/>);
}

export default ImpactMonthDropdown;
