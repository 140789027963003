import React from 'react'
import {PinesList} from "../sharedComponents/pines/PinesList";
import {IndividualQuickFilters} from "./IndividualQuickFilters";
import memoize from "memoize-one";
import {Pine} from "../businessLogic/models/Pine";
import {RevisionsDropdown} from "../sharedComponents/pines/RevisionsDropdown";
import {withAuthContext} from "../AuthContainer";
import CsvButton from "../sharedComponents/CsvButton";
import {ExpressRevisionButton} from "./ExpressRevisionButton";


const bottomActionsContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
};

const bottomRightActionsContainerStyle = {
  ...bottomActionsContainerStyle,
  alignItems: "center"
}

export const individualPageFilters = {
  NO_FILTER: {id: "no filter", filterFunction: i => i},
  GARDENERS: {id: "gardeners", filterFunction: (pines, currentPine) => pines.filter(pine => pine.isPineGardener(currentPine) )},
  HIDE_PROPOSALS: {id: "hideProposals", filterFunction: (pines) => pines.map(pine => {
    let newPine = new Pine(pine.getRawData());
    newPine.initializeProposal();
    return newPine;
  })},
  PROPOSALS:{id: "proposalsPage", filterFunction: (pines) => pines.filter(pine => pine.hasProposal())}
};

class ProposalList extends React.Component {

  state = {activeFilter: individualPageFilters.NO_FILTER};

  applyFilter = (isEnabled, filter) => {
    this.setState({activeFilter: isEnabled ? filter : individualPageFilters.NO_FILTER});
  };

  onFilterProposalsClick = (isEnabled) => {
    this.applyFilter(isEnabled, individualPageFilters.PROPOSALS);
  };

  onGardenersFilterClick = (isEnabled) => {
    this.applyFilter(isEnabled, individualPageFilters.GARDENERS);
  };

  onHideProposalsClick = (isEnabled) => {
    this.applyFilter(isEnabled, individualPageFilters.HIDE_PROPOSALS);
  };

  onAddGardener = (gardener, gardeneree) => {
    gardeneree.addGardener(gardener).then(() => this.props.replacePine(gardeneree, "Ahora sos jardinere de " + gardeneree.getName() + "!"));
  };

  onRemoveGardener = (gardener, gardeneree) => {
    gardeneree.removeGardener(gardener).then(() => this.props.replacePine(gardeneree, "Dejaste de ser jardienere de " + gardeneree.getName()));
  };

  filterPines = memoize(
      (pines, activeFilter, loggedPine) => activeFilter.filterFunction(pines, loggedPine)
  );

  render() {
    const filteredPines = this.filterPines(this.props.pines, this.state.activeFilter, this.props.loggedPine);
    return (
        <div className="proposal-list">
          <div className="header">
            <RevisionsDropdown revision={this.props.currentRevision}
                               revisions={this.props.revisions}
                               onRevisionSelection={this.props.onRevisionSelection}/>
            {this.props.currentRevision && this.props.currentRevision.impactDate &&
            <CsvButton revision={this.props.currentRevision} pines={this.props.pines}/>}
            <ExpressRevisionButton
                pines={this.props.pines}
                onExpressRevisionDelete={this.props.onExpressRevisionDelete}
                onExpressRevisionCreate={this.props.onExpressRevisionCreate}
                onExpressRevisionSave={this.props.onExpressRevisionSave}
                isExpressRevisionOpen={this.props.isExpressRevisionOpen}
            />
            <IndividualQuickFilters activeFilter={this.state.activeFilter}
                                    onGardenersFilterClick={this.onGardenersFilterClick}
                                    onHideProposalsClick={this.onHideProposalsClick}
                                    onFilterProposalsClick={this.onFilterProposalsClick}/>
          </div>

          <PinesList pines={filteredPines} allPines={this.props.pines}
                     onWeakSponsorToggle={this.props.handleWeakSponsorToggle}
                     onAddProposal={this.props.onAddProposal}
                     revisionData={this.props.currentRevision}
          />
        </div>
    );
  }
}

export default withAuthContext(ProposalList)
